import { useMutation } from '@tanstack/react-query'
import { guildApi } from '@pig-common/api'
import { tierMapper } from '@pig-common/utils/tierMapper'
import { IGuildVIPMember } from '@pig-common/types/Guild.type'

interface IGuildVIPMemberParams {
  memberCustomerCodes?: (string | undefined)[]
}

export default function useGuildVIPMember() {
  return useMutation({
    mutationFn: async ({ memberCustomerCodes }: IGuildVIPMemberParams) => {
      const response = await guildApi.getVIPMember({ memberCustomerCodes })
      const responseWithMappedTier = {
        ...response,
        data: tierMapper<IGuildVIPMember[]>(response?.data),
      }
      return responseWithMappedTier
    },
  })
}
