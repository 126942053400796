import { authStateCommon } from '@pig-common/recoils'
import { useQuery } from '@tanstack/react-query'
import { totalTicketApi } from '@pig-common/api'
import { useRecoilValue } from 'recoil'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IGetTotalTicketResponse } from '@pig-common/types/Ticket.type'

interface IGetTicketTypes<SelectReturnType = IGetTotalTicketResponse> {
  select?: (data: CommonResponse<IGetTotalTicketResponse>) => SelectReturnType
  enabled?: boolean
  refetchInterval?: number
}

export default function useGetTicket<
  SelectReturnType = IGetTotalTicketResponse,
>({ select, enabled }: IGetTicketTypes<SelectReturnType>) {
  const { customerCode } = useRecoilValue(authStateCommon.authState)

  return useQuery({
    queryKey: [totalTicketApi.key.getTotal],
    queryFn: () => totalTicketApi.getTotal({ customerCode }),
    enabled,
    select: (data) => (select ? select(data) : data?.data) || {},
  })
}
