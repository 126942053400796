import styled, { keyframes } from 'styled-components'
import Image from 'next/image'

export type PrizeItemStyled = {
  prizeHeight: number
  jackpot?: boolean
}
export const PrizeSlot = styled.div<PrizeItemStyled>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: ${({ prizeHeight }) => `${prizeHeight}px`};
  padding-right: 4ch;
  background: #301f75;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(
      90deg,
      #e456efb1 0%,
      #653df566 30%,
      #653df566 100%
    );
    z-index: 3;
  }
  > div {
    font-size: ${({ prizeHeight }) =>
      `${Math.floor((prizeHeight * 0.8) / 2) * 2}px`};
    font-weight: ${({ jackpot }) => `${jackpot ? 500 : 500}`};
    text-align: right;
    color: #f9ef9d;
    background: linear-gradient(180deg, #fbf7ab 30%, #ca902d 45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
export const RouletteBody = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  img {
    object-fit: scale-down;
    object-position: center;
  }
`
export const RoulettePointer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transform-origin: left;
  transform: scale(0.7);
  img {
    object-fit: scale-down;
    object-position: left;
  }
`
export const RouletteHandler = styled.div<{ active: boolean }>`
  position: absolute;
  left: 91%;
  top: 40%;
  width: 9%;
  height: 9%;
  z-index: 3;
  cursor: pointer;
  img {
    position: absolute;
    top: 0px;
    transform: ${({ active }) => `translateY(${active ? 32 : -32}px)`};
    transition: 0.3s;
    object-fit: scale-down;
    object-position: left;
    z-index: 5;
  }
  &::before {
    content: '';
    transform: ${({ active }) => `rotateX(${active ? '180' : '0'}deg)`};
    transform-origin: bottom;
    transition: 0.3s;
    position: absolute;
    left: calc(50% - 3px);
    width: 6px;
    top: -8px;
    bottom: 50%;
    background: linear-gradient(
      90deg,
      rgba(135, 123, 103, 1) 0%,
      rgba(212, 195, 160, 1) 80%
    );
    border: 1px solid #afaeae;
    z-index: 1;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 6px);
    height: 12px;
    width: calc(50% + 3px);
    background: linear-gradient(
      0deg,
      rgba(135, 123, 103, 1) 0%,
      rgba(250, 241, 224, 1) 75%,
      rgba(212, 195, 160, 1) 100%
    );
    border-bottom: 1px solid #7b7b7b;
    z-index: 2;
  }
`
export const RouletteContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  user-select: none;
`
export const animDecorRuning = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`
export enum RouletteDecorPhase {
  STOP = 0,
  RUNNING = 1,
  FINISH = 2,
}
export const RouletteDecor = styled(Image)<{
  index: number
  animationPhase: RouletteDecorPhase
}>`
  opacity: 0;
  animation-name: ${({ animationPhase }) => {
    switch (animationPhase) {
      case RouletteDecorPhase.FINISH:
      case RouletteDecorPhase.RUNNING:
        return animDecorRuning
      case RouletteDecorPhase.STOP:
      default:
        return ''
    }
  }};
  animation-duration: 0.1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: ${({ index, animationPhase }) =>
    `${
      animationPhase === RouletteDecorPhase.FINISH
        ? parseInt(`${index}`) * 0.25
        : 0
    }s`};
  z-index: 3;
`

export const animMotionBlur = keyframes`
  0% { filter: blur(2px) }
  100% { filter: blur(0px) }
`
export const RouletteScreen = styled.div<{ motionBlur: number }>`
  position: absolute;
  overflow: hidden;
  background-color: grey;
  .roulette-pro-wrapper {
    animation: ${({ motionBlur }) => {
        return motionBlur ? animMotionBlur : 'none'
      }}
      ease-out;
    animation-duration: ${({ motionBlur }) => {
      return `${motionBlur || 0}s`
    }};
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      0deg,
      rgba(2, 0, 36, 0) 28%,
      rgba(0, 0, 0, 0.4) 77%,
      #2d287ab2 100%
    );
    z-index: 3;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 32px;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      180deg,
      rgba(2, 0, 36, 0) 28%,
      rgba(0, 0, 0, 0.4) 77%,
      rgba(0, 0, 0, 0.7) 100%
    );
    z-index: 3;
  }
  .roulette-pro-prize-list li {
    width: 100%;
  }
`
export const RouletteFreeFlag = styled.div`
  content: 'FREE';
  padding: 6px 8px;
  line-height: 1.5ch;
  margin-right: 8px;
  border-radius: 4px;
  background-color: orange;
  color: white;
  display: inline-block;
  vertical-align: middle;
`
