import dynamic from 'next/dist/shared/lib/dynamic'
import React, { useEffect, useMemo, useState } from 'react'
import 'react-roulette-pro/dist/index.css'
import Image from 'next/image'
import isEmpty from 'lodash/isEmpty'
import { Spin } from 'antd'
import { IMAGE_FOLDER_PATH } from '@pig-common/models/buildtime-constant'
import { numberWithCommas } from '@pig-common/utils/number'
import {
  RouletteContainer,
  RouletteBody,
  RouletteDecor,
  RouletteDecorPhase,
  RoulettePointer,
  RouletteHandler,
  RouletteScreen,
  PrizeSlot,
} from './index.styled'

const RoulettePro = dynamic(() => import('react-roulette-pro'), {
  ssr: false,
})
export type PrizeItem = {
  id: string | number
  image: string
  text?: string
}
interface RouletteScreenPosition {
  top: number | string
  left: number | string
  right: number | string
  bottom: number | string
}
interface RouletteProps {
  active: boolean
  loading?: boolean
  position: RouletteScreenPosition
  winPrize: PrizeItem | null
  prizes?: PrizeItem[]
  height: number
  amount: number
  speed?: number
  imgRoullette: string
  imgPointer: string
  imgRoulletteDecor: string[]
  imgHandler?: string
  handlerSpin?: () => void
  onRewardDeclare: (prize: PrizeItem) => void
}

const generateId = () =>
  `${Date.now().toString(36)}-${Math.random().toString(36).substring(2)}`

const Roulette: React.FC<RouletteProps> = ({
  active = false,
  loading = false,
  position,
  prizes = [],
  winPrize,
  height = 72,
  amount = 100,
  speed = 3,
  imgRoullette,
  imgPointer,
  imgRoulletteDecor = [],
  imgHandler = `${IMAGE_FOLDER_PATH}/loyalty/daily-spin/roulette-handler.png`,
  handlerSpin = () => {},
  onRewardDeclare,
}) => {
  const [start, setStart] = useState(active)
  const [finish, setFinish] = useState(false)
  const [displayPrize, setDisplayPrize] = useState<any[]>(prizes)
  const [spinningTime] = useState<number>(speed)
  const [isLoading, setLoading] = useState<boolean>(true)
  const rouletteLength: number = amount
  const prizeHeight: number = height

  const handlePrizeDefined = () => {
    setFinish(true)
    if (!isEmpty(winPrize) && winPrize) onRewardDeclare(winPrize)
  }
  const randomPrize = (min: number, max: number): PrizeItem => {
    const minCap = min > 0.1 ? min : 0.1
    const scaleFactor = Math.random() * (max - minCap) + minCap
    const randomValue = scaleFactor
    return {
      id: generateId(),
      image: '',
      text: `${randomValue}`,
    }
  }
  const prizeShowcaseList = (min = 0, max = 1, length = 0) => {
    return [
      ...Array(Math.ceil(length))
        .fill('_')
        .map(() => randomPrize(min, max)),
    ]
  }
  const mockedPrizeStart = [
    randomPrize(0, 1),
    randomPrize(10, 100),
    randomPrize(1000, 10000),
    randomPrize(0, 1),
    randomPrize(10, 100),
    randomPrize(0, 1),
    randomPrize(0, 1),
    randomPrize(110, 1000),
  ]
  const mockedPrizeBody = [
    ...Array(amount - mockedPrizeStart.length)
      .fill('_')
      .map((e, index) => {
        if (index % 8 === 0 && index !== 0) return randomPrize(100, 1000)
        if (index % 6 === 0 && index !== 0) return randomPrize(0, 10)
        if (index % 4 === 0 && index !== 0) return randomPrize(10, 100)
        if (index % 2 === 0 && index !== 0) return randomPrize(1, 10)
        return randomPrize(0, 1)
      }),
  ]
  const mockedPrizeEnd = prizeShowcaseList(0, 1, 10)

  const shouldMotionBlur = useMemo(() => {
    return start && !finish
  }, [start, finish])

  const renderPrizeList: PrizeItem[] = useMemo(() => {
    if (!isEmpty(prizes)) return prizes
    const reproducedPrizeList = [
      ...mockedPrizeStart,
      ...mockedPrizeBody,
      winPrize ?? null,
      ...mockedPrizeEnd,
    ]
      .map((prize) => {
        return {
          ...prize,
          id: generateId(),
          image: prize?.image || '',
        }
      })
      .filter((prize) => !isEmpty(prize.text))
    return reproducedPrizeList
  }, [winPrize])
  useEffect(() => {
    setDisplayPrize(renderPrizeList)
    setFinish(false)
  }, [winPrize])
  useEffect(() => {
    setStart(active)
    setFinish(false)
  }, [active])

  return (
    <RouletteContainer>
      <RouletteBody>
        <Image
          fill
          src={imgRoullette}
          alt={imgRoullette}
          style={{ objectFit: 'scale-down' }}
          onLoad={() => setLoading(false)}
          loading="eager"
          priority
        />
      </RouletteBody>
      {Array.isArray(imgRoulletteDecor) &&
        imgRoulletteDecor.length > 0 &&
        imgRoulletteDecor.map((img, index) => {
          return (
            <RouletteDecor
              fill
              src={img}
              alt={img}
              style={{ objectFit: 'scale-down' }}
              index={index}
              key={img}
              animationPhase={
                finish
                  ? RouletteDecorPhase.FINISH
                  : start || loading
                  ? RouletteDecorPhase.RUNNING
                  : RouletteDecorPhase.STOP
              }
            />
          )
        })}
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <RoulettePointer style={{ ...position, left: 0 }}>
            <Image
              fill
              src={imgPointer}
              alt={imgPointer}
              priority
              loading="eager"
            />
          </RoulettePointer>
          <RouletteHandler active={start} onClick={() => handlerSpin()}>
            <Image
              fill
              src={imgHandler}
              alt={imgHandler}
              priority
              loading="eager"
            />
          </RouletteHandler>
          <RouletteScreen
            motionBlur={shouldMotionBlur ? spinningTime : 0}
            style={position}
          >
            <RoulettePro
              type="vertical"
              start={start}
              prizes={displayPrize}
              prizeIndex={!isEmpty(winPrize) ? rouletteLength : rouletteLength}
              onPrizeDefined={handlePrizeDefined}
              spinningTime={spinningTime}
              designPlugin={(props) => {
                return {
                  prizeItemWidth: prizeHeight * 3,
                  prizeItemHeight: prizeHeight,
                  spinningTime: 3,
                  stopInCenter: true,
                  type: props.type,
                  prizesWithText: true,
                }
              }}
              prizeItemRenderFunction={(item) => {
                const prizeNumber = parseFloat(item.text || '')
                const prizeValue = item.text
                  ? numberWithCommas(prizeNumber, 2) || item.text
                  : '---'
                const isJackpot = prizeNumber > 1000
                return (
                  <PrizeSlot
                    key={item.id}
                    prizeHeight={prizeHeight}
                    jackpot={isJackpot}
                    data-win={isJackpot}
                    data-testid={`prize-slot-${item.id}`}
                  >
                    <div>{prizeValue}</div>
                  </PrizeSlot>
                )
              }}
            />
          </RouletteScreen>
        </>
      )}
    </RouletteContainer>
  )
}

export default Roulette
